<template>
    <!-- 应急救援支持系统 -->
    <div class="wrap">
        <v-titile title="应急救援支持系统" content="Emergency rescue support system"></v-titile>

        <el-row :span="24" :gutter="40">
            <el-col :span="8" class="img_wrap">
                <div class="grid-content bg-purple">
                    <img src="../assets/banner_left.png" width="100%" height="440px;" alt />
                    <div class="content">
                        <span class="comp">composition</span>
                        <span class="test">系统组成</span>
                        <div class="bar"></div>
                    </div>
                </div>
            </el-col>
            <el-col :span="8" class="list_wrap">
                <div class="grid-content bg-purple">
                    <div v-for="(item,index) in leftPart" :key="index" class="part">
                        <img :src="item.img" width="44" alt />
                        <div class="title">{{item.title}}</div>
                        <p class="content">{{item.content}}</p>
                    </div>
                </div>
            </el-col>
            <el-col :span="8" class="list_wrap">
                <div class="grid-content bg-purple">
                    <div v-for="(item,index) in rightPart" :key="index" class="part">
                        <img :src="item.img" width="44" alt />
                        <div class="title">{{item.title}}</div>
                        <p class="content">{{item.content}}</p>
                    </div>
                </div>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <div class="grid-content bg-purple-dark list">
                    <div class="list_1">
                        <span>智慧应急指挥调度系统</span>
                        <span>水域救灾救援支持系统</span>
                    </div>
                    <div class="list_2">社会消防力量监管与调度系统</div>
                    <div class="list_3">
                        <span>重点单位灾害预警系统</span>
                        <span>森林火灾预警系统</span>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import Title from './Title'
export default {
    components: {
        'v-titile': Title
    },
    data() {
        return {
            leftPart: [
                {
                    img: require('@/assets/icon_1.png'),
                    title: '空中',
                    content: '长航时监测无人机，重型救援无人机'
                },
                {
                    img: require('@/assets/icon_3.png'),
                    title: '通信指挥',
                    content:
                        '电子沙盘,应急指挥终端,现场指挥沙盘一体化作战单兵,专家库支持系统,二级网络'
                }
            ],
            rightPart: [
                {
                    img: require('@/assets/icon_2.png'),
                    title: '地面',
                    content:
                        '灭火机器人,室内外定位,重点单位消防预警森林防火预警'
                },
                {
                    img: require('@/assets/icon_4.png'),
                    title: '水域',
                    content:
                        '智能动力救生圈,水面搜救艇,水下搜索仪蛙人通信定位系统,单侧部署阻拦网'
                }
            ]
        }
    },
    methods: {},
    mounted() {}
}
</script>
<style lang="scss" scoped>
.wrap {
    max-width: 1024px;
    margin: 0 auto;
    overflow: hidden;
    .img_wrap {
        position: relative;
        overflow: hidden;
        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, -20%);
            .comp {
                font-size: 18px;
                font-weight: 400;
                color: rgba(27, 1, 2, 1);
            }
            .test {
                font-size: 24px;
                font-weight: 600;
                color: rgba(51, 51, 51, 1);
            }
            .bar {
                margin-top: 8px;
                width: 124px;
                height: 11px;
                background: rgba(0, 138, 255, 1);
            }
        }
    }
    .list_wrap {
        .part {
            position: relative;
            padding: 60px 0px 10px 20px;
            box-sizing: border-box;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 10px 20px 0px rgba(0, 138, 255, 0.1);
            border-radius: 4px;
            margin-bottom: 40px;
            min-height: 200px;
            &:last-child {
                margin-bottom: 0;
            }
            img {
                position: absolute;
                top: 20%;
                right: 10%;
            }
            .title {
                font-size: 24px;
                font-weight: 600;
                color: rgba(51, 51, 51, 1);
            }
            .content {
                font-size: 16px;
                font-weight: 400;
                color: rgba(27, 1, 2, 1);
            }
        }
    }
    .list {
        background: rgba(0, 138, 255, 1);
        box-shadow: 0px 10px 20px 0px rgba(0, 138, 255, 0.1);
        border-radius: 16px;
        border: 10px solid rgba(255, 255, 255, 1);
        padding: 34px 66px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        .list_1 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 32px;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
        }
        .list_2 {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 36px;
            font-weight: 600;
            color: rgba(255, 255, 255, 1);
            margin-top: 46px;
            margin-bottom: 40px;
        }
        .list_3 {
            display: flex;
            align-items: center;
            justify-content: space-around;
            font-size: 32px;
            font-weight: 400;
            color: rgba(255, 255, 255, 1);
            &:last-child {
                font-size: 28px;
                font-weight: 300;
                color: rgba(255, 255, 255, 1);
            }
        }
    }
}
</style>
