<template>
    <div>
        <v-Header></v-Header>
        <v-Tel></v-Tel>
        <v-Top v-show="side" class="animated fadeInUp fast" @click.native="goTop"></v-Top>
        <div class="home" ref="home">
            <div>
                <div ref="banner">
                    <v-Banner :bannerObj="bannerObj"></v-Banner>
                </div>
                <div style="width:100%;height:1070px" ref="part1">
                    <v-Part1 v-show="part1" class="animated fadeInUp fast"></v-Part1>
                </div>
                <div style="width:100%;height:1320px" ref="part2">
                    <v-Part2 v-show="part2" class="animated fadeInUp fast"></v-Part2>
                </div>
                <div style="width:100%;height:1300px" ref="part3">
                    <v-Part3 v-show="part3" class="animated fadeInUp fast"></v-Part3>
                </div>
                <div style="width:100%;height:556px" ref="part4">
                    <v-Part4 v-show="part4" class="animated fadeInUp fast"></v-Part4>
                </div>
                <div style="width:100%;height:295px" ref="bottom">
                    <v-Bottom v-show="bottom" class="animated fadeInUp fast"></v-Bottom>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Banner from '@/components/Banner.vue'
import Part1 from '@/components/Part1.vue'
import Part2 from '@/components/Part2.vue'
import Part3 from '@/components/Part3.vue'
import Part4 from '@/components/Part4.vue'
import Tel from '@/components/Tel.vue'
import Top from '@/components/Top.vue'
import Bottom from '@/components/Bottom.vue'
import BScroll from 'better-scroll'
export default {
    name: 'home',
    data() {
        return {
            scrollY: '',
            part1: false,
            part2: false,
            part3: false,
            part4: false,
            bottom: false,
            side: false,
            bannerObj: {
                title: '应急救援C4ISR',
                desc: '科技助力救援 创新服务应急',
                culture: '让消防官兵少流血少流泪',
                img: require('@/assets/banner_01.png')
            }
        }
    },
    components: {
        'v-Header': Header,
        'v-Banner': Banner,
        'v-Part1': Part1,
        'v-Part2': Part2,
        'v-Part3': Part3,
        'v-Part4': Part4,
        'v-Tel': Tel,
        'v-Top': Top,
        'v-Bottom': Bottom
    },
    methods: {
        initScroll() {
            this.$nextTick(() => {
                if (!this.$refs.home) {
                    return
                }
                //配置: 可根据个人需求
                this.listScroll = new BScroll(this.$refs.home, {
                    probeType: 1,
                    scrollY: true,
                    click: true,
                    useTransition: false, // 防止iphone微信滑动卡顿
                    bounce: true,
                    momentumLimitDistance: 5,
                    mouseWheel: {
                        speed: 20,
                        invert: false,
                        easeTime: 0
                    }
                })
                //
                this.listScroll.on('scroll', pos => {
                    let part1 = this.$refs.part1.offsetTop - 400
                    let part2 = this.$refs.part2.offsetTop - 400
                    let part3 = this.$refs.part3.offsetTop - 400
                    let part4 = this.$refs.part4.offsetTop - 400
                    let banner = this.$refs.banner.clientHeight
                    // 使用abs绝对值（否则 pos.y拿到值是负数）
                    this.scrollY = Math.abs(Math.round(pos.y))
                    //判断滑动距离大于"商品介绍"元素时, 吸顶title,否则隐藏
                    if (this.scrollY >= part1) {
                        this.part1 = true
                    }
                    if (this.scrollY >= part2) {
                        this.part2 = true
                    }
                    if (this.scrollY >= part3) {
                        this.part3 = true
                    }
                    if (this.scrollY >= part4) {
                        this.part4 = true
                    }
                    if (this.scrollY + 200 >= part4) {
                        this.bottom = true
                    }
                    if (this.scrollY > banner) {
                        this.side = true
                    } else {
                        this.side = false
                    }
                })
            })
        },
        goTop() {
            this.listScroll.scrollTo(0, 0, 200)
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.initScroll()
        })
    }
}
</script>
<style lang="scss" scoped>
.home {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
}
</style>
