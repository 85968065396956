<template>
    <div class="wrap">
        <v-titile title="SR产品" content="监控、侦查"></v-titile>
        <div class="list_wrap">
            <div class="list" v-for="(item,index) in item" :key="index">
                <div class="top">{{item.title}}</div>
                <div class="content">
                    <div class="point">
                        <el-popover
                            v-for="(tags,index) in item.desc"
                            :key="index"
                            placement="top-start"
                            width="200"
                            trigger="hover"
                            :content="tags.btn"
                        >
                            <el-button class="tag" slot="reference">{{tags.content}}</el-button>
                        </el-popover>
                    </div>
                    <div class="img">
                        <el-image :src="item.img" style="width: 100%; height: 100%" fit="fill"></el-image>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Title from './Title'
export default {
    components: {
        'v-titile': Title
    },
    data() {
        return {
            item: [
                {
                    title: '长航时监测无人机',
                    img: require('@/assets/part2_1.png'),
                    desc: [
                        {
                            content: '超长续航',
                            btn: '最大滞空时间长达6小时，标准监测载荷工作3小时'
                        },
                        {
                            content: '强抗风',
                            btn: '最大抗风7级，无惧恶劣天气'
                        },
                        {
                            content: '使用无忧',
                            btn: '多家保险公司全程支持，使用无忧'
                        },
                        {
                            content: '应用场景',
                            btn:
                                '事故现场持续监测，事故周边预警，应急通信临时空中基站'
                        }
                    ]
                },
                {
                    title: '重型救援无人机',
                    img: require('@/assets/part2_2.png'),
                    desc: [
                        {
                            content: '大载重',
                            btn: '最大载重70Kg，可定制150Kg级'
                        },
                        {
                            content: '强抗风',
                            btn: '最大抗风7级，无惧恶劣天气'
                        },
                        {
                            content: '使用无忧',
                            btn: '多家保险公司全程支持，使用无忧'
                        },
                        {
                            content: '应用场景',
                            btn:
                                '交通困难地点物资投放，救援拦阻网布设，救援绳索投放，与智能救生圈协同工作，事故现场监测'
                        }
                    ]
                },
                {
                    title: '应急智能救生圈',
                    img: require('@/assets/part2_3.png'),
                    desc: [
                        {
                            content: '高航速',
                            btn: '空载最高航速：海水≥7米/秒，淡水≥6米/秒'
                        },
                        {
                            content: '大载重',
                            btn: '最大载重120kg，可搭载普通救生圈实现多人救援'
                        },
                        {
                            content: '投放方便',
                            btn: '自重8.5kg，轻松投放'
                        },
                        {
                            content: '应用场景',
                            btn:
                                '内河、近海、湖泊、水库水下环境扫测；水域失踪人员搜寻'
                        }
                    ]
                },
                {
                    title: '水下应急搜索仪',
                    img: require('@/assets/part2_4.png'),
                    desc: [
                        {
                            content: '大扫宽',
                            btn: '单侧最大扫宽可达150m，满足绝大多数场合需要'
                        },
                        {
                            content: '可便携',
                            btn: '重量仅7kg，安装使用方便 '
                        },
                        {
                            content: '使用无忧',
                            btn: '多家保险公司全程支持，使用无忧'
                        },
                        {
                            content: '应用场景',
                            btn:
                                '内河、近海、湖泊、水库水下环境扫测；水域失踪人员搜寻'
                        }
                    ]
                },
                {
                    title: '蛙人通信定位系统',
                    img: require('@/assets/part2_5.png'),
                    desc: [
                        {
                            content: '定位通信系统',
                            btn:
                                '水下应急综合定位通信系统是集定位、通信于一体，保障了作业人员的安全的综合系统'
                        },
                        {
                            content: '设备协同工作',
                            btn:
                                '通过水面水下设备协同工作，实现了水面水下实时定位和通信'
                        },
                        {
                            content: '沟通交流的桥梁',
                            btn:
                                '可用于潜水、水下搜救、水下施工作业、水下执行特殊任务等，建立水面水下沟通交流的桥梁'
                        }
                    ]
                }
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.wrap {
    max-width: 1024px;
    margin: 0 auto;
    .btn {
        display: flex;
        justify-content: center;
        margin-top: -30px;
    }
    .list_wrap {
        display: flex;
        flex-direction: row;
        margin-top: 120px;
        flex-flow: wrap;
        justify-content: space-around;
        .list {
            background-color: #fff;
            padding-top: 60px;
            box-sizing: border-box;
            position: relative;
            width: 30%;
            cursor: pointer;
            border-radius: 8px;
            margin-right: 30px;
            margin-bottom: 120px;
            &:hover {
                box-shadow: 0px 10px 20px 0px rgba(215, 217, 222, 1);
            }
            .top {
                text-align: center;
                width: 120px;
                height: 120px;
                border-radius: 120px;
                background: #008aff;
                color: #fff;
                box-shadow: 0px 10px 20px 0px rgba(215, 217, 222, 1);
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 24px;
                box-sizing: border-box;
                position: absolute;
                left: 50%;
                top: -20%;
                transform: translate3d(-50%, 0, 0);
            }
            .content {
                .point {
                    padding: 6px 12px;
                    box-sizing: border-box;
                    .tag {
                        border-radius: 100px;
                        background: rgba(89, 178, 254, 1);
                        border-radius: 20px;
                        border: 1px solid rgba(0, 138, 255, 1);
                        margin-right: 20px !important;
                        margin-bottom: 22px;
                        color: #fff;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
                .img {
                    width: 100%;
                    height: 170px;
                    overflow: hidden;
                    border-radius: 0 0 8px 8px;
                }
            }
        }
    }
}
</style>