<template>
    <div class="wrap">
        <v-titile title="案例展示" content="致力于服务好每位客户"></v-titile>
        <el-carousel :interval="4000" type="card" height="290px" autoplay indicator-position="none">
            <el-carousel-item v-for="(item,index) in imgs" :key="index" class="items">
                <div class="item">
                    <div class="bar">
                        <span class="title">{{item.title}}</span>
                    </div>
                    <img :src="item.img" width="100%" alt />
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>
<script>
import Title from './Title'
export default {
    components: {
        'v-titile': Title
    },
    data() {
        return {
            imgs: [
                {
                    img: require('@/assets/banner_mini_1.png'),
                    title: '2018年乌镇互联网峰会开幕式'
                },
                {
                    img: require('@/assets/banner_mini_2.png'),
                    title:
                        '2018年10月国务院委员王勇利用我司调度平台调研浙江总队及嘉兴专职队'
                },
                {
                    img: require('@/assets/banner_mini_3.png'),
                    title: '央视报道利用指挥系统和水下搜索系统组合开展救援工作'
                }
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.wrap {
    max-width: 1024px;
    margin: 0 auto;
    .items {
        .item {
            position: relative;
            .bar {
                width: 100%;
                height: 60px;
                background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 1) 100%
                );
                left: 0;
                right: 0;
                bottom: 0;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                .title {
                    padding: 6px 12px;
                    box-sizing: border-box;
                    text-align: center;
                }
            }
        }
    }
}
</style>