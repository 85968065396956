<template>
    <div class="wrap">
        <v-titile title="C4I系统" content="指挥、控制、通信、计算机"></v-titile>
        <el-row class="btn">
            <el-button @click="goTable" type="primary" round plain>应急救援C4ISR系统配置表</el-button>
        </el-row>
        <div class="list_wrap">
            <div class="list" v-for="(item,index) in items" :key="index">
                <div class="top">{{item.top}}</div>
                <div class="content">
                    <div class="text">
                        <div class="desc" v-for="(desc,index) in item.text" :key="index">{{desc}}</div>
                    </div>
                    <div class="img">
                        <el-image :src="item.img" style="width: 100%; height: 100%" fit="fill"></el-image>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Title from './Title'
export default {
    components: {
        'v-titile': Title
    },
    data() {
        return {
            items: [
                {
                    img: require('@/assets/part1_1.png'),
                    top: '智慧应急指挥调度系统',
                    text: [
                        '1. 全组织覆盖  可实现从国家消防总局、省总队、支队、大队、中队、专职队、微型消防站七级联动',
                        '2. 一站式管理  支持对讲、定位、视频会议、网格划分、人员管理、器械管理、技能培训、物资管理',
                        '3. 多网络支持  在保证网络安全的情况下，实现公网和专网的透明传输，提高网络利用率',
                        '4. 兼容现有业务系统  兼容现有消防接警系统、视频会议系统',
                        '5. 超大吞吐量  支持2000个节点同时在线，满足大型救援活动的顺利进行'
                    ]
                },
                {
                    img: require('@/assets/part1_2.png'),
                    top: '智慧多功能单兵',
                    text: [
                        '1. 支持头戴式摄像机及耳麦',
                        '2. 支持区域化接警及119平台出警',
                        '3. 具有抢险蛇眼功能',
                        '4. 重点单位巡检功能 ',
                        '5. 视频会议',
                        '6. 接收推送信息',
                        '7. 支持调用预案库相关视频及信息',
                        '8. 定位及人体体征采集',
                        '9. 自带物资平台管理信息'
                    ]
                },
                {
                    img: require('@/assets/part1_3.png'),
                    top: '应急移动指挥终端',
                    text: [
                        '1. 支持头戴式摄像机及耳麦',
                        '2. 视频会议、白板作战部署',
                        '3. 出警信息、出警现场相关信息调用及推送',
                        '4. 现场作战部署实时绘制实时推送',
                        '5. 二级应急网络管理（支持地下室等网络通讯）',
                        '6. 人员定位显示',
                        '7. 现场人体体征监测 ',
                        '8. 车辆定位显示',
                        '9. 增援力量自动进入现场调度管理体系',
                        '10. 支持卫星设备转播等'
                    ]
                },
                {
                    img: require('@/assets/part1_4.jpg'),
                    top: '应急指挥终端',
                    text: [
                        '1. 视频会议、技能培训、案例互动、各队点名、装备使用巡查、跨平台转播（支持卫星设备转播）',
                        '2. 支持人员定位（室内外定位融合一体，室内轨迹定位精度为1米左右等功能）现场人体体征监测',
                        '3. 车辆定位（车辆状态、行驶路线、驾驶员状态安全提醒等功能）',
                        '4. 接警出警时，自动搜索现场周边力量、弹幕出警现场相关视频及预案信息（重点单位专用视频调用推送、重点单位内部CAD结构图实时线路绘制及推送、外围及重点单位内部水源点推送等预案数据库调用）白板作战绘制',
                        '5. 物资平台管理：（对各级消防机构的整体物资及各队出警消耗、训练消耗、装备年检、装备过期、装备报废、装备采购计划、装备配发进行管理）'
                    ]
                },
                {
                    img: require('@/assets/part1_5.png'),
                    top: '应急车辆监管系统',
                    text: [
                        '1. 显示记录、人员人脸识别、车辆行驶状态、车牌、线路、超低速等车辆信息',
                        '2. 远程支持音视频监控，双向语音对讲，远程云台控制，手动报警，超速报警，越界以及线路偏离报警；及驾驶行为提醒，后方来车、左右转弯、倒车都有提醒功能',
                        '3. 意外事故下断电保护功能:采用特别UPS技术, 可使机器在切断外部电源下仍可正常工作10到15秒,以保证录象文件的完整性,方便事故查询',
                        '4. 支持交通部部标协议，可扩展云台控制，油量传感器等',
                        '5. 客户端程序，提供实时监看、对讲、回放、录像、高清晰截屏等多种功能'
                    ]
                }
            ]
        }
    },
    methods: {
        goTable() {
            this.$router.push({ path: '/table' })
        }
    }
}
</script>
<style lang="scss" scoped>
.wrap {
    max-width: 1024px;
    margin: 0 auto;
    overflow: hidden;
    .btn {
        display: flex;
        justify-content: center;
        margin-top: -30px;
    }
    .list_wrap {
        display: flex;
        flex-direction: row;
        margin-top: 120px;
        flex-wrap: wrap;
        justify-content: space-around;
        .list {
            background-color: #fff;
            padding-top: 90px;
            box-sizing: border-box;
            position: relative;
            width: 30%;
            margin-bottom: 120px;
            cursor: pointer;
            border-radius: 8px;
            transition: all 0.3s;
            &:hover {
                box-shadow: 0px 10px 20px 0px rgba(215, 217, 222, 1);
                background-color: rgba(0, 138, 255, 1);
                color: #fff;
            }
            &:hover .content .text {
                overflow: hidden;
                height: 240px;
            }
            &:hover .content .text .desc {
                font-size: 12px;
            }
            &:hover .content .img {
                display: none;
            }
            &:hover .top {
                background: rgba(0, 138, 255, 1);
                color: #fff;
            }
            .top {
                text-align: center;
                width: 120px;
                height: 120px;
                border-radius: 120px;
                background: rgba(255, 255, 255, 1);
                box-shadow: 0px 10px 20px 0px rgba(215, 217, 222, 1);
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 24px;
                box-sizing: border-box;
                position: absolute;
                left: 50%;
                top: -20%;
                transform: translate3d(-50%, 0, 0);
            }
            &:hover.content .img {
                display: none;
            }
            .content {
                .text {
                    padding: 0 16px 0;
                    box-sizing: border-box;
                    margin-bottom: 16px;
                    overflow: hidden;
                    height: 120px;
                    .desc {
                        font-size: 15px;
                    }
                }
                .img {
                    width: 100%;
                    height: 170px;
                    overflow: hidden;
                    border-radius: 0 0 8px 8px;
                }
            }
        }
    }
}
</style>